import React from 'react';
import { useState, useEffect, useReducer } from "react";
import FormControl from "@material-ui/core/FormControl";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@material-ui/core/TextField";
import { List } from "@material-ui/icons";
import { Button, Col, Row } from "../../components";
import { Heading } from "../../components/index";
import Content from "../../layouts/content";
import { RootStateOrAny, useDispatch, useSelector } from "react-redux";
import { getCompaniesList } from "../../redux/actions/memberAction";
import axios, { AxiosError } from 'axios';
import { SERVICEURL } from '../../appconfig';
import VisibilityIcon from '@mui/icons-material/Visibility';
import IconButton from '@mui/material/IconButton';
import { Container, useStylesButton, useStylesHeader, useStyles, TableTwo, TableHeader, TableCell, TableRowTwo } from '../../shared/styles/commonStyles';
import { Helmet } from 'react-helmet';

interface Status {
    id: number;
    name: string;
}
interface TotalCost {
    CustomerId: number;
    TotalCost: number;
}
interface Payment {
    ProjectId: number;
    ProjectName: string;
   // InvoicedAmount: number;
    RemainingAmount: number;
    ProjectStartDate: string;
    ProjectEndDate: string;
    ProjectCurrentCompletionDate: string;
    ProjectCost: number;
    ExchangeRateForTlBuy: number;
}
interface CostDetail {
    Period: string;
    TotalCost: number;
    ProjectId: number;
}
interface ProgressPayment {
    ProjectId: number;
    TlAmount: number;
    InvoiceDate: string;
    InvoicedAmount: number;
    InvoiceStatus: string;
}

const ProjectReport: React.FC = () => {

    const dis = useDispatch();

    const classesHeader = useStylesHeader();
    const classesName = useStylesButton();
    const classesButton = useStylesButton();
    const classesStyle = useStyles();


    type State = {
        companyId: number | null;
        projectId: number | null;
        loading: boolean;
        isActive: boolean | null;
        period: Date | null;
    }
    const initialState: State = {
        companyId: 2,
        projectId: 0,
        loading: false,
        isActive: null,
        period: new Date(),
    }

    type Action =
        | { type: 'setCompanyId'; payload: number | null }
        | { type: 'setProjectId'; payload: number | null }
        | { type: 'setLoading'; payload: boolean }
        | { type: 'setIsActive'; payload: boolean | null }
        | { type: 'setPeriod'; payload: Date | null };

    const reducer = (state: State, action: Action): State => {
        switch (action.type) {

            case 'setCompanyId':
                return {
                    ...state,
                    companyId: action.payload,
                };

            case 'setProjectId':
                return {
                    ...state,
                    projectId: action.payload,
                };

            case 'setLoading':
                return {
                    ...state,
                    loading: action.payload,
                };

            case 'setIsActive':
                return {
                    ...state,
                    isActive: action.payload,
                };

            case 'setPeriod':
                return {
                    ...state,
                    period: action.payload,
                };
        }
    };

    const [company, setCompany] = React.useState<any | null>(2);
    const { companiesList } = useSelector((state: RootStateOrAny) => state.companies);
    const [companyİnput, setCompanyİnput] = React.useState("RDC PARTNER");
    const [companyOption, setCompanyOption] = React.useState("RDC PARTNER");
    const [selectedStatus, setSelectedStatus] = useState<Status | null>(null);
    const [projectList, setProjectList] = useState([]);
    const [projectValue, setProjectValue] = React.useState<any | null>();
    const [projectOptionName, setProjectOptionName] = React.useState("");
    const [state, dispatch] = useReducer(reducer, initialState);
    const mytoken = localStorage.getItem("token");
    const [openError, setOpenError] = React.useState(false);
    const [fİnancialReportList, setFİnancialReportList] = useState<any[]>([]);
    const [totalCost, setTotalCost] = useState<number | null>(null);
    const [currentMonthTotalCost, setCurrentMonthTotalCost] = useState<number | null>(null);
    const [estimatedCost, setEstimatedCost] = useState<number | null>(null);
    const [detailedCosts, setDetailedCosts] = useState<any[]>([]);
    const [mostRecentPeriod, setMostRecentPeriod] = useState<string>('');
    const [invoiceDate, setInvoiceDate] = useState<string | null>(null);
    const [myDecimal, setMyDecimal] = useState(0.0);
    const [progressPaymentDetailList, setProgressPaymentDetailList] = useState<ProgressPayment[]>([]);
    const [totalInvoicedAmount2, setTotalInvoicedAmount2] = useState<number>(0); // Faturalanan Tutar

    function getCompanies() {
        dis(getCompaniesList());
    }

    useEffect(() => {
        getCompanies();
    }, []);

    const getProject = (isActive: boolean | null) => {
        const input = {
            CompanyID: state.companyId,
            IsActive: isActive,
        };
        axios.post(SERVICEURL + 'api/Data/GetProjectList', input, HEADER)
            .then(res => {
                if (res.data != undefined && res.data != null) {

                    setProjectList(res.data.CustomerList);
                    dispatch({
                        type: 'setLoading',
                        payload: false,
                    });
                }

            })
            .catch((reason: AxiosError) => {
                if (reason.response!.status === 401) {
                    setOpenError(true);
                    dispatch({
                        type: 'setLoading',
                        payload: false,
                    });
                } else {
                    console.log("Handle else:", reason.message);
                }

            })

    };

    useEffect(() => {
        getProject(state.isActive);
    }, [state.isActive, state.companyId]);

    const FİnancialReportList = () => {
        const input = {
            CompanyId: state.companyId,
            ProjectId: state.projectId,
            Period: state.period,
            isActive: state.isActive,
        };
        console.log("Gönderilen input:", input);

        axios.post(SERVICEURL + 'api/Finance/FİnancialReportList', input, HEADER)
            .then(res => {
                console.log("API yyYanıtı:", res.data);
                if (res.data) {
                    setFİnancialReportList(res.data);
                    dispatch({
                        type: 'setLoading',
                        payload: false,
                    });
                }
            })
            .catch((reason: AxiosError) => {
                if (reason.response!.status === 401) {
                    setOpenError(true);
                    dispatch({
                        type: 'setLoading',
                        payload: false,
                    });
                } else {
                    console.log("Handle else:", reason.message);
                }
            });
    };

    const getProgressPaymentDetailList = () => {

        const input = {
            CompanyId: company,
            ProjectId: state.projectId,
            Period: state.period,
            isActive: state.isActive,
        };
        axios
            .post(
                SERVICEURL + "api/Finance/GetProjectProgressPaymentDetailListbyId",
                input,
                HEADER
            )
            .then((res) => {
                console.log(" Hakediş Detay:", res.data);
                if (res.data != undefined && res.data != null) {
                    const totalAmount = calculateInvoicedAmountTotal(res.data);
                    console.log("Toplam Fatura Edilen Miktar:", totalAmount);
                    setTotalInvoicedAmount2(totalAmount);
                    setProgressPaymentDetailList(res.data);
                    dispatch({
                        type: "setLoading",
                        payload: false,
                    });
                }
            })
            .catch((reason: AxiosError) => {
                if (reason.response!.status === 401) {

                    setOpenError(true);

                    dispatch({
                        type: "setLoading",
                        payload: false,
                    });
                } else {
                    console.log("Handle else:", reason.message);
                }
            });
    };

    const getLatestInvoiceDate = () => {
        const input = { ProjectId: state.projectId };
        axios.post(SERVICEURL + 'api/Finance/GetLatestInvoiceDate', input, HEADER)
            .then(res => {
                console.log(res.data);
                if (res.data && res.data.InvoiceDate) {
                    console.log("En son fatura tarihi: ", res.data.InvoiceDate);
                    const formattedDate = new Date(res.data.InvoiceDate).toLocaleDateString();
                    console.log("Formatted En son fatura tarihi: ", formattedDate);
                    setInvoiceDate(formattedDate);
                } else {
                    console.log("Fatura bulunamadı");
                }
            })
            .catch((error) => {
                console.log("API hatası: ", error);
            });
    };

    useEffect(() => {
        getLatestInvoiceDate();
    }, []);

    const Costt = () => {
        const input = {
            CompanyId: state.companyId,
            ProjectId: state.projectId,
            Period: state.period,
            isActive: state.isActive,
        };

        console.log("Gönderilen input:", input);
        setMostRecentPeriod('');

        axios.post(SERVICEURL + "api/Report/CostReport", input, HEADER)
            .then(res => {
                console.log("Maliyet API Yanıtı:", res.data);

                if (res.data) {
                    const currentProjectCost = res.data.totalCosts.find((cost: TotalCost) => cost.CustomerId === state.projectId);
                    setTotalCost(currentProjectCost ? currentProjectCost.TotalCost : 0);

                    const currentMonthCost = res.data.currentMonthTotalCosts.find((cost: TotalCost) => cost.CustomerId === state.projectId);
                    setCurrentMonthTotalCost(currentMonthCost ? currentMonthCost.TotalCost : 0);

                    const projectDetails = res.data.detaillist.filter((item: any) => item.CustomerId === state.projectId);
                    console.log("Seçilen Proje Detayları:", projectDetails);

                    if (projectDetails.length > 0) {


                        const relevantDetails: CostDetail[] = projectDetails.map((item: any) => ({
                            ProjectId: state.projectId,
                            Period: item.period,
                            Giro: item.giro,
                            TotalCost: item.totalcost,
                            Brut: item.brüt
                        }));


                        const sortedDetails = relevantDetails.sort((a: CostDetail, b: CostDetail) => {
                            const [monthA, yearA] = a.Period.split('/').map(Number);
                            const [monthB, yearB] = b.Period.split('/').map(Number);

                            if (yearA !== yearB) {
                                return yearA - yearB;
                            }

                            return monthA - monthB;
                        });


                        const detailsWithPreviousCost = sortedDetails.map((item: CostDetail, index: number, array: CostDetail[]) => {
                            const previousCost = index > 0 ? array[index - 1].TotalCost : 0;
                            return {
                                ...item,
                                PreviousCost: previousCost
                            };
                        });

                        const mostRecentPeriod = detailsWithPreviousCost.length > 0
                            ? detailsWithPreviousCost[detailsWithPreviousCost.length - 1].Period
                            : '';
                        setMostRecentPeriod(mostRecentPeriod);

                        console.log("Filtrelenmiş detaylar:", detailsWithPreviousCost);
                        setDetailedCosts(detailsWithPreviousCost);
                    } else {
                        setDetailedCosts([]);
                    }
                }

                dispatch({
                    type: 'setLoading',
                    payload: false,
                });
            })
            .catch((reason: AxiosError) => {
                if (reason.response!.status === 401) {
                    setOpenError(true);
                    dispatch({
                        type: 'setLoading',
                        payload: false,
                    });
                } else {
                    console.log("Handle else:", reason.message);
                }
            });
    };

    const getInvoicedAmount = () => {


        const input = {
            CompanyId: state.companyId,
            ProjectId: state.projectId,
            Period: state.period,
            isActive: state.isActive,
        };
        axios
            .post(SERVICEURL + "api/Finance/GetInvoicedAmount", input, HEADER)
            .then((res) => {
                console.log("Faturalanan Tutar TL:", res.data);
                if (res.data != undefined && res.data != null) {
                    setMyDecimal(res.data);
                    dispatch({
                        type: "setLoading",
                        payload: false,
                    });
                }
            })
            .catch((reason: AxiosError) => {
                if (reason.response!.status === 401) {
                    //setTimeout(handleLoadingClose, 500)

                    setOpenError(true);

                    dispatch({
                        type: "setLoading",
                        payload: false,
                    });
                } else {
                    console.log("Handle else:", reason.message);
                }
            });
    };
    const handleCompanies = (event: any, newValue: any | null) => {
        console.log("Selected Company:", newValue);
        if (newValue != null) {
            setCompany(newValue.id);
            dispatch({ type: 'setCompanyId', payload: newValue.id });
        } else {
            setCompany(null);
            dispatch({ type: 'setCompanyId', payload: null });
        }
    };

    const handleChangeCompanyInput = (
        event: any,
        newValueInput: any | null
    ) => {
        if (newValueInput != null && newValueInput != undefined) {
            setCompanyOption(newValueInput);
            setCompanyİnput(newValueInput);
        }
        if (newValueInput == null) {
            setCompanyİnput(newValueInput);
        }
    };

    const handleChangeProject = (event: any, newValue: any | null) => {
        if (newValue != null && newValue != undefined) {
            setProjectValue(newValue);
            dispatch({
                type: 'setProjectId',
                payload: newValue.id,
            });
        }
        if (newValue == null) {
            setProjectValue(newValue);
            dispatch({
                type: 'setProjectId',
                payload: 0,
            });
        }
    };

    const HEADER = {
        headers: { 'Content-Type': 'application/json', 'Authorization': 'Bearer ' + mytoken }
    }

    const statusList: Status[] = [
        { id: 1, name: 'Aktif' },
        { id: 2, name: 'Pasif' },
    ];

    const handleStatusChange = (event: any, newValue: Status | null) => {
        setSelectedStatus(newValue);


        const isActive = newValue ? newValue.id === 1 : null;

        dispatch({
            type: 'setIsActive',
            payload: isActive,
        });


        getProject(isActive);
    };

    const formatDateString = (dateString: string) => {
        const [day, month, year] = dateString.split('/');
        return `${year}-${month}-${day}`;
    };

    const calculateExtraTime = (ProjectCurrentCompletionDate: string, ProjectEndDate: string): number => {
        const actualDate = new Date(formatDateString(ProjectCurrentCompletionDate));
        const end = new Date(formatDateString(ProjectEndDate));

        if (isNaN(actualDate.getTime()) || isNaN(end.getTime())) {
            return 0;
        }

        const timeDifference = actualDate.getTime() - end.getTime();
        const dayDifference = timeDifference / (1000 * 3600 * 24);
        const monthDifference = dayDifference / 30;

        return monthDifference;
    };

    const calculateRemainingMonths = (projectEndDate: string): number => {
        const end = new Date(formatDateString(projectEndDate));
        const today = new Date();

        if (isNaN(end.getTime())) {
            return 0;
        }

        const timeDifference = end.getTime() - today.getTime();
        const dayDifference = timeDifference / (1000 * 3600 * 24);
        const monthDifference = dayDifference / 30;

        return Math.max(0, monthDifference);
    };



    useEffect(() => {
        if (currentMonthTotalCost !== null && totalCost !== null) {
            const totalRemainingMonths = fİnancialReportList.reduce((total, payment) => {
                return total + calculateRemainingMonths(payment.ProjectEndDate);
            }, 0);


            const estimated = (currentMonthTotalCost * totalRemainingMonths) + totalCost;


            console.log("Son Ayın Maliyeti:", currentMonthTotalCost);
            console.log("Kalan Süre (Ay):", totalRemainingMonths);
            console.log("Maliyet:", totalCost);
            console.log("Tahmini Maliyet:", estimated);

            setEstimatedCost(estimated);
        }
    }, [currentMonthTotalCost, totalCost, fİnancialReportList]);


    const calculateKZ = (payment: Payment, calculatedProjectCostTl: number | null) => {
        const grossKZ = (myDecimal !== null && totalCost !== null) ? (myDecimal - totalCost) : null;
        let estimatedKZ: number | null = null;

        if (calculatedProjectCostTl !== null && estimatedCost !== null) {
            estimatedKZ = calculatedProjectCostTl - estimatedCost;
        }

        return { grossKZ, estimatedKZ };
    };

    const isPeriodCompleted = (period: string) => {
        const [month, year] = period.split('/').map(Number);
        const periodDate = new Date(year, month - 1, 1);
        const today = new Date();

        return periodDate <= today;
    };


    const formatNumber = (value: number | string) => {
        if (value === 'Hesaplanmadı') return 'Hesaplanmadı';
        return new Intl.NumberFormat('tr-TR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2,
        }).format(value as number);
    };

    //Faturalanan Tutar Hesabı////
    const calculateInvoicedAmountTotal = (data: ProgressPayment[]): number => {
      
        const filteredData = data.filter(item => item.InvoiceStatus === "Faturalandı");
        console.log("Filtrelenmiş Veri (Faturalandı):", filteredData);
        const totalInvoicedAmount2 = filteredData.reduce((total, item) => total + (item.InvoicedAmount || 0), 0);
    
    return totalInvoicedAmount2;
    };


    const handleListClick = () => {
        if (state.companyId) {
            dispatch({ type: 'setLoading', payload: true });
            FİnancialReportList();
            Costt();
            getLatestInvoiceDate();
            getInvoicedAmount();
            getProgressPaymentDetailList();
        }
    };

    const Nav = React.lazy(() => import("../../layouts/nav/Nav"));

    return (
        <>
            <Helmet>
                <title>RDC Board - Proje Rapor</title>
            </Helmet>
            <div className={classesStyle.scroll}>
                <Nav />
                <div className={classesName.colorBackground}>
                    <Content>
                        <Heading as="h5" mb="5px" className={classesHeader.header}>
                            Proje Rapor
                        </Heading>
                        <Container className={classesName.TextFieldControl}>
                            <Row>
                                <Col lg={4} md sm xs mb={10}>
                                    <FormControl fullWidth variant="outlined">
                                        {
                                            Object.values(companiesList).length > 0 &&
                                            <Autocomplete
                                                options={Object.values(companiesList.data.Companies).map((companiesItem: any) => companiesItem)}
                                                getOptionLabel={(options) => options.name == undefined ? companyOption : options.name}
                                                value={company == null ? 0 : company}
                                                onChange={handleCompanies}
                                                inputValue={companyİnput}
                                                onInputChange={handleChangeCompanyInput}
                                                renderInput={params => (<TextField
                                                    variant="outlined"
                                                    {...params}
                                                    label="Şirket"

                                                />
                                                )}
                                            />
                                        }
                                    </FormControl>
                                </Col>
                                <Col lg={4} md sm xs mb={10}>
                                    <FormControl fullWidth variant="outlined">
                                        <Autocomplete
                                            options={statusList}
                                            getOptionLabel={(option) => option.name}
                                            value={selectedStatus}
                                            onChange={handleStatusChange}
                                            renderInput={params => (<TextField
                                                variant="outlined"
                                                {...params}
                                                label="Proje Durumu"
                                            />
                                            )}
                                        />
                                    </FormControl>
                                </Col>
                                <Col lg={4} md sm xs mb={10}>
                                    <FormControl fullWidth variant="outlined">
                                        <Autocomplete
                                            options={projectList}
                                            getOptionLabel={(options) => options.name == undefined ? projectOptionName : options.name}
                                            value={projectValue == null ? 0 : projectValue}
                                            onChange={handleChangeProject}
                                            renderInput={params => (<TextField
                                                variant="outlined"
                                                {...params}
                                                label="Proje "
                                            />
                                            )}
                                        />
                                    </FormControl>
                                </Col>
                                <Col lg md sm xs mb={10}>
                                    <Button iconButton size="lg" color="light" className={classesButton.buttonList} onClick={handleListClick}  >
                                        <List width={18} height={18} className={classesButton.buttonIcon} />
                                        LİSTELE
                                    </Button>
                                </Col >
                                <Col lg={12} md={12} sm={12} xs={12} mb={10} >
                                    {fİnancialReportList.length > 0 ? (
                                        <div>
                                            {fİnancialReportList.map(payment => {
                                                const RemainingAmount=payment.ProjectCost-totalInvoicedAmount2
                                                const calculatedProjectCostTl = myDecimal + (RemainingAmount * payment.ExchangeRateForTlBuy);
                                                const calculatedRemainingAmountTl = RemainingAmount * payment.ExchangeRateForTlBuy;
                                                const extraTime = calculateExtraTime(payment.ProjectCurrentCompletionDate, payment.ProjectEndDate);
                                                const remainingMonths = calculateRemainingMonths(payment.ProjectEndDate);
                                                const { grossKZ, estimatedKZ } = calculateKZ(payment, calculatedProjectCostTl);
                                                const formatCurrency = (value: number) => {
                                                    return new Intl.NumberFormat('tr-TR', {
                                                        minimumFractionDigits: 2,
                                                        maximumFractionDigits: 3
                                                    }).format(value);
                                                };


                                                return (
                                                    <div className='project-main' key={payment.ProjectId} style={{ marginBottom: '20px', padding: '15px', border: '1px solid #ddd', borderRadius: '8px', boxShadow: '0 2px 10px rgba(0, 0, 0, 0.1)' }}>
                                                        <h5 style={{ marginBottom: '20px', fontSize: '20px', color: '#333' }}><strong>Proje Adı:</strong> {payment.ProjectName}</h5>
                                                        <div style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(305px, 1fr))', gap: '20px', marginBottom: '20px' }}>
                                                            <div style={{ display: 'flex', flexDirection: 'column',  borderRight: '1px solid #ddd' }}>
                                                                <div style={{ marginBottom: '10px', whiteSpace: 'nowrap', display: 'flex' }}><strong style={{ minWidth: '170px' }}>Başlangıç Tarihi</strong><strong>:&nbsp;</strong> {payment.ProjectStartDate}</div>
                                                                <div style={{ marginBottom: '10px', whiteSpace: 'nowrap', display: 'flex' }}><strong style={{ minWidth: '170px' }}>Bitiş Tarihi</strong><strong>:&nbsp;</strong> {payment.ProjectEndDate}</div>
                                                                <div style={{ marginBottom: '10px', whiteSpace: 'nowrap', display: 'flex' }}><strong style={{ minWidth: '170px' }}>Gerçekleşen Bitiş Tarihi</strong> <strong>:&nbsp;</strong>{payment.ProjectCurrentCompletionDate}</div>
                                                                <div style={{ marginBottom: '10px', whiteSpace: 'nowrap', display: 'flex' }}><strong style={{ minWidth: '170px' }}>Ek Süre (Ay)</strong> <strong>:&nbsp;</strong>{extraTime.toFixed(2)}</div>
                                                            </div>
                                                            <div style={{ display: 'flex', flexDirection: 'column', borderRight: '1px solid #ddd' }}>
                                                                <div style={{ marginBottom: '10px', display: 'flex' }}><strong style={{ minWidth: '130px' }}>Proje Bedeli</strong> <strong>:&nbsp;</strong>{formatCurrency(payment.ProjectCost)}{payment.ProjectCostSymbol}</div>
                                                                <div style={{ marginBottom: '10px', display: 'flex', whiteSpace: 'nowrap' }}><strong style={{ minWidth: '130px' }}>Faturalanan Tutar</strong><strong>:&nbsp;</strong>{invoiceDate && ` (${invoiceDate})`}{formatCurrency(totalInvoicedAmount2)}{payment.ProjectCostSymbol} </div>
                                                                <div style={{ marginBottom: '10px', display: 'flex' }}><strong style={{ minWidth: '130px' }}>Kalan Tutar</strong><strong>:&nbsp;</strong> {formatCurrency(RemainingAmount)}{payment.ProjectCostSymbol}</div>
                                                                {/* <div><strong>Kalan Süre (Ay):</strong> {remainingMonths !== null ? remainingMonths.toFixed(2) : 'Hesaplanmadı'}</div>
                                <div><strong>Son Ayın Maliyeti:</strong> {currentMonthTotalCost !== null ? currentMonthTotalCost : 'Hesaplanmadı'}</div> */}
                                                            </div>
                                                            {payment.ExchangeRateForTlBuy !== 1 && (
                                                                <div style={{ display: 'flex', flexDirection: 'column',  borderRight: '1px solid #ddd' }}>
                                                                    <div style={{ marginBottom: '10px', display: 'flex' }}
                                                                        title="Faturalanan tl tutara, kalan tutarın günlük kur ile çarpılmış hali eklenmiştir."
                                                                    >
                                                                        <strong style={{ minWidth: '146px' }}>Proje Bedeli Tl</strong><strong>:&nbsp;</strong> {calculatedProjectCostTl && formatCurrency(calculatedProjectCostTl)}₺</div>
                                                                    <div style={{ marginBottom: '10px', whiteSpace: 'nowrap', display: 'flex' }}><strong style={{ minWidth: '146px' }}>Faturalanan Tutar Tl</strong><strong>:&nbsp;</strong> {formatCurrency(myDecimal)}₺</div>
                                                                    <div style={{ marginBottom: '10px', display: 'flex' }}><strong style={{ minWidth: '146px' }}>Kalan Tutar Tl</strong><strong>:&nbsp;</strong>{calculatedRemainingAmountTl && formatCurrency(calculatedRemainingAmountTl)}₺</div>
                                                                    <div style={{ marginBottom: '10px', display: 'flex' }}><strong style={{ minWidth: '146px' }}>Kur Değeri</strong> <strong>:&nbsp;</strong>{payment.ExchangeRateForTlBuy} </div>
                                                                </div>
                                                            )}
                                                            <div style={{ display: 'flex', flexDirection: 'column'}}>
                                                                <div style={{ marginBottom: '10px', whiteSpace: 'nowrap', display: 'flex' }}><strong style={{ minWidth: '120px' }}>Brüt K/Z</strong>{mostRecentPeriod && `(${mostRecentPeriod})`}<strong>:&nbsp;</strong> {grossKZ !== null ? formatCurrency(grossKZ) : 'Hesaplanmadı'}₺</div>
                                                                <div style={{ marginBottom: '10px', display: 'flex' }}><strong style={{ minWidth: '120px' }}>Tahmini K/Z</strong><strong>:&nbsp;</strong> {estimatedKZ !== null ? formatCurrency(estimatedKZ) : 'Hesaplanmadı'}₺</div>
                                                                <div style={{ marginBottom: '10px', display: 'flex' }}><strong style={{ minWidth: '120px' }}>Maliyet</strong>{mostRecentPeriod && `(${mostRecentPeriod})`}<strong>:&nbsp;</strong> {totalCost !== null ? formatCurrency(totalCost) : 'Hesaplanmadı'}₺</div>
                                                                <div style={{ marginBottom: '10px', display: 'flex' }}><strong style={{ minWidth: '120px' }}>Tahmini Maliyet</strong><strong>:&nbsp;</strong> {estimatedCost !== null ? formatCurrency(estimatedCost) : 'Hesaplanmadı'}₺</div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    ) : null}
                                </Col>
                            </Row>
                            {detailedCosts.length > 0 && (
                                <TableTwo>
                                    <thead className='projects-report-main'>
                                        <TableRowTwo>
                                            <TableHeader>Dönem</TableHeader>
                                            <TableHeader>
                                                Ciro
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        const projectId = detailedCosts.length > 0 ? detailedCosts[0]?.ProjectId : null;
                                                        if (projectId) {
                                                            window.open(`/projectProgressPaymentDetail/${projectId}`, '_blank');
                                                        } else {
                                                            console.error('Project ID bulunamadı');
                                                        }
                                                    }}
                                                    style={{ marginLeft: '10px' }}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </TableHeader>
                                            <TableHeader>Ciro₺</TableHeader>
                                            <TableHeader>
                                                Maliyet
                                                <IconButton
                                                    size="small"
                                                    onClick={() => {
                                                        window.open('/sumProfitabilityReport', '_blank');
                                                    }}
                                                    style={{ marginLeft: '10px' }}
                                                >
                                                    <VisibilityIcon />
                                                </IconButton>
                                            </TableHeader>
                                            <TableHeader>Brüt k/z</TableHeader>
                                        </TableRowTwo>
                                    </thead>
                                    <tbody className='projects-report-main-body'>
                                        {detailedCosts.map((detail, index) => {
                                            const isCompleted = isPeriodCompleted(detail.Period);

                                            const matchingProgressPayments = progressPaymentDetailList.filter((payment) => {
                                                const invoiceDateMonthYear = payment.InvoiceDate
                                                    ? (() => {
                                                        const date = payment.InvoiceDate;
                                                        const formattedDate = date ? date.substring(3, 10) : "";
                                                        return formattedDate;
                                                    })()
                                                    : "";

                                                return invoiceDateMonthYear === detail.Period;
                                            });

                                            const totalTlAmount = matchingProgressPayments.reduce((sum, payment) => sum + payment.TlAmount, 0);
                                            const totalInvoicedamount=matchingProgressPayments.reduce((sum, payment) => sum + payment.InvoicedAmount, 0);// ciro
                                            const brutKZ = totalTlAmount - detail.TotalCost;

                                            return (
                                                <TableRowTwo key={index}>
                                                    <TableCell isTotalRow={true}>{detail.Period}</TableCell>
                                                    <TableCell isTotalRow={true}>{formatNumber(totalInvoicedamount)}</TableCell>
                                                    <TableCell isTotalRow={true}>{formatNumber(totalTlAmount)}₺</TableCell>
                                                    <TableCell isCompleted={isCompleted}>{formatNumber(detail.TotalCost)}</TableCell>
                                                    <TableCell isTotalRow={true}>{formatNumber(brutKZ)}</TableCell>
                                                </TableRowTwo>
                                            );
                                        })}
                                        <TableRowTwo>
                                            <TableCell isTotalRow={true}  >Toplam</TableCell>
                                            <TableCell isTotalRow={true}>
                                                {formatNumber(detailedCosts.reduce((acc, detail) => {
                                                    const matchingPayments = progressPaymentDetailList.filter((payment) => {
                                                        const invoiceDateMonthYear = payment.InvoiceDate
                                                            ? payment.InvoiceDate.substring(3, 10)
                                                            : "";
                                                        return invoiceDateMonthYear === detail.Period;
                                                    });
                                                    const totalInvoicedAmount = matchingPayments.reduce((sum, payment) => sum + payment.InvoicedAmount, 0);       
                                                     return acc + totalInvoicedAmount;
                                                }, 0))}
                                            </TableCell>                                          
                                            <TableCell isTotalRow={true}>
                                                {formatNumber(detailedCosts.reduce((acc, detail) => {
                                                    const matchingPayments = progressPaymentDetailList.filter((payment) => {
                                                        const invoiceDateMonthYear = payment.InvoiceDate
                                                            ? payment.InvoiceDate.substring(3, 10)
                                                            : "";
                                                        return invoiceDateMonthYear === detail.Period;
                                                    });
                                                    const totalTlAmount = matchingPayments.reduce((sum, payment) => sum + payment.TlAmount, 0);
                                                    return acc + totalTlAmount;
                                                }, 0))}
                                            </TableCell>
                                            <TableCell isTotalRow={true}>
                                                {formatNumber(detailedCosts.reduce((acc, detail) => acc + Number(detail.TotalCost), 0))}
                                            </TableCell>
                                            <TableCell isTotalRow={true}> 
                                                {formatNumber(detailedCosts.reduce((acc, detail) => {
                                                const matchingPayments = progressPaymentDetailList.filter((payment) => {
                                                const invoiceDateMonthYear = payment.InvoiceDate
                                                    ? payment.InvoiceDate.substring(3, 10)
                                                    : "";
                                                    return invoiceDateMonthYear === detail.Period;
                                                    });
                                                const totalTlAmount = matchingPayments.reduce((sum, payment) => sum + payment.TlAmount, 0);
                                                const brutKZ = totalTlAmount - detail.TotalCost;
                                                return acc + brutKZ;
                                                  }, 0))}
                                            </TableCell>
                                        </TableRowTwo>
                                    </tbody>
                                </TableTwo>
                            )}
                        </Container>
                    </Content>
                </div>
            </div >
        </>
    );
};
export default ProjectReport;